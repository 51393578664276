var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: "10%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "排序值",
  dataIndex: "sort",
  key: "sort",
  width: "10%",
  scopedSlots: {
    customRender: "sort"
  }
}, {
  title: "示意图",
  dataIndex: "image",
  key: "image",
  width: "15%",
  scopedSlots: {
    customRender: "image"
  }
}, {
  title: "新闻标题",
  dataIndex: "name",
  key: "name",
  width: "20%",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "媒体名称",
  dataIndex: "media",
  key: "media",
  width: "10%",
  scopedSlots: {
    customRender: "media"
  }
}, {
  title: "跳转链接",
  dataIndex: "url",
  key: "url",
  width: "20%",
  scopedSlots: {
    customRender: "url"
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: "status",
  width: "10%",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };