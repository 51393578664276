import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { NewsLetterApi } from "@/api";
import Mixin from "@/utils/mixin";
import UploadImg from "@/components/UploadImg";
import previewMixin from "@/utils/previewMixin";
export default {
  name: "newsletterForm",
  components: {
    UploadImg: UploadImg
  },
  props: {
    editId: [String, Number],
    status: [Number]
  },
  mixins: [Mixin, previewMixin],
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      disabledLoading: false,
      item: {},
      action: ""
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return false;
    }
  },
  created: function created() {
    // console.log(this.status, this.editId);
    if (this.editId) {
      this.find();
    }
  },
  methods: {
    find: function find() {
      var _this = this;
      this.disabledLoading = true;
      this.spinning = true;
      NewsLetterApi.find(this.editId, {}).then(function (res) {
        _this.item = res;
        _this.disabledLoading = false;
      }).catch(function (err) {
        _this.toast(err || "接口报错", "error");
      }).finally(function () {
        _this.spinning = false;
      });
    },
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this2 = this;
      if (this.disabledLoading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.disabledLoading = true;
          var params = {};
          params = _objectSpread({}, values);
          if (_this2.editId) {
            params.id = _this2.editId;
          }
          NewsLetterApi.save(params).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this2.disabledLoading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
};