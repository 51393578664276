var searchKeys = [{
  key: "title",
  label: "新闻标题",
  placeholder: "新闻标题",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "url",
  label: "跳转链接",
  placeholder: "跳转链接",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };