import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import previewMixin from "@/utils/previewMixin";
import { searchKeys, columns } from "./config/index";
import { NewsLetterApi } from "@/api";
import Form from "./components/form.vue";
import { ADD } from "@/utils/buttons";
export default {
  name: "newsletter",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      formLoading: false,
      timer: null,
      columns: columns,
      searchKeys: searchKeys,
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      defaultIndex: 0,
      types: [{
        id: null,
        name: "全部"
      }, {
        id: 0,
        name: "待发布"
      }, {
        id: 1,
        name: "已发布"
      }]
    };
  },
  mixins: [Mixin, TableMixin, previewMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    modalTitle: function modalTitle() {
      return this.action == "add" ? "新增" : this.action == "edit" ? "编辑" : '';
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    statusText: function statusText(status) {
      var it = this.types.find(function (item) {
        return item.id === status;
      });
      return (it === null || it === void 0 ? void 0 : it.name) || "";
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this = this;
      this.subLoading = true;
      NewsLetterApi.delete({
        id: this.editId
      }).then(function () {
        _this.toast("操作成功", "success");
        _this.isConfirmVisible = false;
        _this.subLoading = false;
        _this.getList();
      }).catch(function () {
        _this.isConfirmVisible = false;
        _this.subLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.selectedRowKeys = [];
          _this2.loading = true;
          if (!values.status) delete values.status;
          var params = _objectSpread({
            page: _this2.page,
            size: _this2.size,
            status: _this2.status
          }, values);
          NewsLetterApi.list(_objectSpread({}, params)).then(function (res) {
            _this2.loading = false;
            _this2.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this2.total = (res === null || res === void 0 ? void 0 : res.total) || _this2.total;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "edit") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == ADD) {
        this.modalVisible = true;
        this.source = {};
      } else if (action == "delete") {
        this.isConfirmVisible = true;
      } else {
        return;
      }
    },
    tabClick: function tabClick(e, index) {
      this.createForm.resetFields(["status"]);
      this.defaultIndex = index;
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD)
      if (action == ADD) {
        this.handleAction(action, {});
        return;
      }
    } /*******表格复选框end********/
  }
};